<template>
  <div class="main-page">
    <video muted autoplay style="height: 30%; border-radius: 10px;" src="/demo.mp4"></video><br><br>

    👋 <b>Hi</b>, to start receiving your videos in the bot from your OBS –
    install the plugin from the links and try to send a couple of <i>replay buffer</i><br><br>
    <b>OBS plugin for all platforms:</b><br>
    <a href="https://github.com/krol44/obs-telegram-replay-plugin/releases">https://github.com/krol44/obs-telegram-replay-plugin/releases</a><br><br>
    <b>Fast link, windows plugin for obs:</b><br>
    <a href="https://github.com/krol44/obs-telegram-replay-plugin/releases/download/1.3.1/obs-telegram-replay-plugin-1.3.1-windows-x64-Installer.exe"
    >https://github.com/krol44/obs-telegram-replay-plugin/releases/download/1.3.1/obs-telegram-replay-plugin-1.3.1-windows-x64-Installer.exe</a><br><br>
    <i>This plugin is open source, the code and the plugin installation program are compiled using GitHub. The code is completely open to the community and safe.</i><br><br>
    You can get support when I'm online here – <a href="https://www.twitch.tv/capybarafps">https://www.twitch.tv/capybarafps</a>
  </div>
</template>

<style>
.main-page {
	position: fixed;
    margin-top: 20px;
	width: 100%;
	height: 100%;
	background-color: #0e0e15;
	color: whitesmoke;
    text-align: center;
}

.main-page a {
	color: whitesmoke;
}
</style>