<template>
  <div class="lock-loading">
    Application work only into OBSReplayBot –&nbsp;<a href="https://t.me/OBSReplayBot">https://t.me/OBSReplayBot</a>
  </div>
</template>

<style>
.lock-loading {
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 20;
	background-color: #0e0e15;
	justify-content: center;
	align-items: center;
	display: flex;
	color: whitesmoke;
	text-align: center;
}

.lock-loading a {
	color: whitesmoke;
}
</style>