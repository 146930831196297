import { createRouter, createWebHistory } from 'vue-router';
import Main from '@/views/Main-page.vue';
import Replay from '@/views/Replay-prime.vue';
import ReplayEditor from '@/views/Replay-editor.vue';

const routes = [
	{
		path: '/',
		name: 'Replay main',
		component: Main,
	},
	{
		path: '/replay',
		name: 'Replay app',
		component: Replay,
	},
	{
		path: '/replay-editor',
		name: 'Replay Editor',
		component: ReplayEditor,
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;