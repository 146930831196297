<template>
  <div v-if="!isLoadedVideo" class="lock-loading">
    <div class="progress-bar stripes animated reverse slower">
      <span class="progress-bar-inner" :style="{ width: percentLoaded + '%' }" />
      <div class="progress-bar-inner-percent">{{ percentLoaded }}%</div>
    </div>
  </div>

  <div ref="dinamicHeightVideoBgRef" class="no-select"
       style="position: absolute; overflow: hidden; width: 100%; height: 100vh;">
    <canvas class="no-select" id="image" style="
       position: absolute;
       width: 100%;
       filter: blur(40px);
       backdrop-filter: blur(40px);
       transform: scale(1.15);
       opacity: .8;
       z-index: 1;" />
  </div>

  <div style="padding: 10px 25px;">
    <div style="display: flex; justify-content: center; align-items: center; position: relative;">
      <div style="position: relative; width: 100%; opacity: 1; z-index: 10;">
        <video ref="playerRef" autoplay controls muted playsinline
               :src="videoSrc" type="video/mp4"
               style="width: 100%; max-height: 300px; border-radius: 5px;" />
      </div>
    </div>
  </div>

  <div class="moment-editor-player" style="z-index: 10;">
    <div class="player" />
    <div class="seeking no-select">
      <div class="controls no-select">
        <span class="left">
          <span />
        </span> <span class="right">
          <span />
        </span>
      </div>
      <div class="current no-select" />
    </div>
  </div>

  <div class="expl-btn-block no-select"
       style="position: relative; color: white; padding: 10px 25px; z-index:15; opacity: 1; text-align: center;">
    <div style="display: flex; justify-content: center;">
      <textarea maxlength="50" placeholder="Description..." class="moment-text"></textarea>
    </div>
    <div style="display: flex; justify-content: center;">
      <div @click.prevent="sendTimeline('bot')" class="expl-btn-main">
        <div class="expl-btn" style="margin-right: 10px;"><span>Send to a bot</span></div>
      </div>
      <div @click.prevent="sendTimeline('place')" class="expl-btn-main">
        <div class="expl-btn"><span>Send to a place</span></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, useTemplateRef } from 'vue'

const player = useTemplateRef('playerRef')
const dinamicHeightVideoBg = useTemplateRef('dinamicHeightVideoBgRef')
const isLoadedVideo = ref(false)
const percentLoaded = ref(0)
const videoSrc = ref('')
const isTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0

let initData = ''
let apiUrl = ''
let isDev = process.env.NODE_ENV === 'development'
if (isDev) {
	apiUrl = 'http://127.0.0.1:8033'
} else {
	apiUrl = 'https://obs-replay.krol44.com'
	isDev = window.location.host.includes('.test')
}
let telegram = {}

onMounted(() => {
		window.addEventListener('resize', () => {
			dinamicHeightVideoBg.value.style.height = document.body.clientHeight + 'px'
		})
		dinamicHeightVideoBg.value.style.height = document.body.clientHeight + 100 + 'px'

		telegram = window.Telegram
		telegram.WebApp.ready()
		telegram.WebApp.expand()
		telegram.WebApp.disableVerticalSwipes()
		initData = telegram.WebApp.initData

		if ((initData === '' || new URLSearchParams(location.search).get('unique_id') === '') && !isDev) {
			document.querySelector('.lock-loading').innerHTML =
				`Application work only into OBSReplayBot –&nbsp;<a href="https://t.me/OBSReplayBot">https://t.me/OBSReplayBot</a>`
			return
		}

		telegram.WebApp.HapticFeedback.notificationOccurred('warning')
		fetchVideo()
		playPromise()
		if (!isTouch) {
			player.value.muted = false
			player.value.volume = 0.3
		}

		window.addEventListener('blur', () => {
			player.value.muted = true
			player.value.pause()
		})
		window.addEventListener('focus', function () {
			player.value.muted = false
			player.value.play()
		})

		let width
		let height
		let image = document.getElementById('image')
		let ctx = image.getContext('2d')
		const draw = () => {
			width = parseInt(document.defaultView.getComputedStyle(player.value).width)
			height = parseInt(document.defaultView.getComputedStyle(player.value).height)
			image.width = width
			image.height = height

			ctx.drawImage(player.value, 0, 0, width, height)
			requestAnimationFrame(draw)
		}
		draw()

		function renderVideo () {
			const video = player.value

			video.addEventListener('timeupdate', function () {
				document.querySelector('.seeking .current').style.width =
					(((video.currentTime / video.duration) * 100) || 0) + '%'

				const controls = document.querySelector('.seeking .controls')
				if (video.currentTime > +controls.getAttribute('data-to') - 0.1) {
					video.currentTime = +controls.getAttribute('data-from')
				}
			}, false)

			document.querySelector('.moment-editor-player .seeking').addEventListener('click', function (element) {
				if (element.target.className !== 'controls no-select')
					return
				const offset = element.target.offsetLeft
				const position = (element.offsetX + offset) / element.currentTarget.clientWidth * video.duration
				if (position) {
					video.currentTime = +position
					video.play()
				}
			})

			let canPlayInit = false
			video.addEventListener('canplay', function () {
				if (canPlayInit)
					return
				canPlayInit = true

				const secMin = 2
				const secMax = 1800
				if (!video.duration || video.duration <= 0) {
					alert('error: no detect duration')
					return false
				}

				let seekMomentMax = (secMax / video.duration) * 100 || 0
				if (seekMomentMax > 100)
					seekMomentMax = 100

				const seekMomentMin = (secMin / video.duration) * 100 || 0

				const elementCurrent = document.querySelector('.seeking .controls')
				elementCurrent.style.marginRight = (100 - seekMomentMax) + '%'
				elementCurrent.dataset.from = 0
				elementCurrent.dataset.to = (seekMomentMax / 100) * video.duration

				const seekMomentAction = function (side, event, min, max) {
					let slider = document.querySelector('.seeking'),
						width = slider.offsetWidth,
						moment = document.querySelector('.seeking .controls'),
						left = parseFloat(moment.style.marginLeft) || 0,
						right = (100 - parseFloat(moment.style.marginRight)) || 100

					if (isTouch) {
						event = event.touches[0]
					}
					let pageX = event.pageX - slider.getBoundingClientRect().left

					let pos = pageX / width * 100

					if (side === 'left')
						left = pos

					if (side === 'right')
						right = pos

					if (left <= 0) left = 0
					if (left >= 100) left = 100

					if (right <= 0) right = 0
					if (right >= 100) right = 100

					if ((right - left) < min) {
						if (side === 'left' && left + min <= 100)
							right = left + min

						if (side === 'right' && right - min <= 0)
							right = left + min

						left = right - min
					}

					if ((right - left) > max) {
						let offset = (right - left) - max

						if (side === 'left')
							right -= offset

						if (side === 'right')
							left += offset
					}

					if (side === 'left')
						video.currentTime = +((left / 100) * video.duration)

					if (side === 'right')
						video.currentTime = +((right / 100) * video.duration - (min / 100) * video.duration)

					moment.style.marginLeft = left + '%'
					moment.style.marginRight = (100 - right) + '%'
					moment.dataset.from = (left / 100) * video.duration
					moment.dataset.to = (right / 100) * video.duration
				}

				let eventStart = 'mousedown'
				let eventMove = 'mousemove'
				let eventUp = 'mouseup'
				if (isTouch) {
					eventStart = 'touchstart'
					eventMove = 'touchmove'
					eventUp = 'touchend'
				}

				document.querySelectorAll('.seeking > .controls > span').forEach(function (element) {
					element.addEventListener(eventStart, function () {
						document.body.addEventListener(eventMove, onMouseMove)

						function onMouseMove (event) {
							event.preventDefault()
							seekMomentAction(element.className, event, seekMomentMin, seekMomentMax)
						}

						document.body.addEventListener(eventUp, function onMouseUp () {
							document.body.removeEventListener(eventMove, onMouseMove)
							document.body.removeEventListener(eventStart, onMouseUp)
						})
					})
				})
			}, false)
		}

		renderVideo()
	}
)

const fetchVideo = async () => {
	const response = await fetch(apiUrl + '/getVideo', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Dev': isDev.toString(),
		},
		body: JSON.stringify({ init_data: initData, unique_id: new URLSearchParams(location.search).get('unique_id') })
	})
	if (response.status !== 200 && !isDev) {
		telegram.WebApp.showAlert('Video not found, because the file storage time is up 🥲', () => {
			telegram.WebApp.close()
		})
		return
	}

	const reader = await response.body.getReader()
	const contentLength = +response.headers.get('Content-Length')

	let loadedBytes = 0
	let chunks = []

	let isActive = true
	while (isActive) {
		const { done, value } = await reader.read()
		if (done) {
			isActive = false
			break
		}

		loadedBytes += value.length
		chunks.push(value)

		percentLoaded.value = Math.round((loadedBytes / contentLength) * 100)
	}

	const videoBlob = new Blob(chunks, { type: 'video/mp4' })
	videoSrc.value = URL.createObjectURL(videoBlob)

	telegram.WebApp.HapticFeedback.notificationOccurred('success')
	isLoadedVideo.value = true
}

function playPromise () {
	const promise = player.value.play()
	if (promise !== undefined) {
		promise.then().catch(() => {
			player.value.autoplay = true
		})
	}
}

let cutVideoSet = false
const sendTimeline = (where) => {
	const send = () => {
		if (cutVideoSet) {
			return
		}
		cutVideoSet = true

		player.value.muted = true
		player.value.pause()

		const controls = document.querySelector('.seeking .controls')

		fetch(apiUrl + '/cuttingVideo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Dev': isDev.toString(),
			},
			body: JSON.stringify(
				{
					init_data: initData,
					unique_id: new URLSearchParams(location.search).get('unique_id'),
					start_pos: +controls.getAttribute('data-from'),
					end_pos: +controls.getAttribute('data-to'),
					place: where,
					text: document.querySelector('.moment-text').value
				}
			)
		}).then(res => {
			if (res.status === 405) {
				alert('You need to add OBSReplayBot in the channel or group')
				cutVideoSet = false
				return
			}

			if (res.status === 200) {
				telegram.WebApp.HapticFeedback.notificationOccurred('success')
				document.querySelector('.moment-text').value = ''
				document.querySelector('.expl-btn-block').innerHTML = 'Success! I\'ll send you a video soon 🔥'
				setTimeout(() => {
					player.value.parentNode.innerHTML = ''
					telegram.WebApp.close()
				}, 2000)
			}
		})
	}
	if (where === 'place') {
		telegram.WebApp.showConfirm('Are you sure?', ok => {
			if (ok) {
				send()
			}
		})
	} else {
		send()
	}
}
</script>

<style>
@font-face {
	font-family: 'Rubik';
	src: url('/public/fonts/Rubik-Regular.ttf') format('truetype'); /* путь к файлу */
	font-weight: normal;
	font-style: normal;
}

@keyframes animate-stripes {
	0% {
		background-position: 0 0;
	}

	100% {
		background-position: 60px 0;
	}
}

body {
	margin: 0;
	background-color: #0e0e15;
	font-family: 'Rubik', sans-serif;
	overflow: hidden;
}

.lock-loading {
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 20;
	background-color: #0e0e15;
	justify-content: center;
	align-items: center;
	display: flex;
	color: whitesmoke;
	text-align: center;
}

.lock-loading a {
	color: whitesmoke;
}

.progress-bar {
	position: relative;
	background-color: #1a1a1a;
	height: 45px;
	width: 80%;
	margin: 50px auto;
	border-radius: 5px;
	box-shadow: 0 1px 5px #000 inset, 0 1px 0 #444;
}

.stripes {
	background-size: 30px 30px;
	background-image: linear-gradient(
			135deg,
			rgba(255, 255, 255, .15) 25%,
			transparent 25%,
			transparent 50%,
			rgba(255, 255, 255, .15) 50%,
			rgba(255, 255, 255, .15) 75%,
			transparent 75%,
			transparent
	);
}

.stripes.animated {
	animation: animate-stripes 0.6s linear infinite;
}

.stripes.animated.slower {
	animation-duration: 1.25s;
}

.stripes.reverse {
	animation-direction: reverse;
}

.progress-bar-inner {
	display: block;
	height: 45px;
	width: 0;
	background-color: #8a7ddf;
	border-radius: 3px;
	box-shadow: 0 1px 0 rgba(255, 255, 255, .5) inset;
	position: relative;
	transition: all 0.1s ease-out;
}

.progress-bar-inner-percent {
	color: whitesmoke;
	text-align: center;
	position: absolute;
	width: 100%;
	top: 16px;
}

.moment-editor-player {
	border: 1px solid #e0b4f9;
	margin: 0 27px 30px;
	position: relative;
}

.moment-editor-player .seeking {
	width: 100%;
	height: 30px;
	position: relative;
	background: rgba(31, 39, 71, .3);
	cursor: pointer;
}

.moment-editor-player .current {
	width: 0;
	height: 30px;
	background-color: #4e4f8e;
	position: absolute;
	cursor: pointer;
	pointer-events: none;
	transition: all 0.5s linear;
	border-right: 2px solid orangered;
}

.moment-editor-player .controls {
	position: absolute;
	left: 0;
	right: 0;
	width: auto;
	z-index: 5;
	height: 30px;
	margin: 0;
	border: 1px solid #8a7ddf;
	box-sizing: border-box;
	background-color: rgba(78, 79, 142, .3);
}

.moment-editor-player .controls > .left {
	width: 8px;
	height: 60px;
	background-color: #8a7ddf;
	position: absolute;
	top: -15px;
	cursor: ew-resize;
	z-index: 1;
	margin-left: -4px;
	border-radius: 2px;
}

.moment-editor-player .controls > .left > span {
	width: 20px;
	height: 65px;
	position: absolute;
	margin: -6px;
	cursor: ew-resize;
	z-index: 2;
}

.moment-editor-player .controls > .right {
	width: 8px;
	height: 60px;
	background-color: #8a7ddf;
	position: absolute;
	top: -15px;
	cursor: ew-resize;
	z-index: 1;
	margin-right: -4px;
	right: 0;
	border-radius: 2px;
}

.moment-editor-player .controls > .right > span {
	width: 20px;
	height: 65px;
	position: absolute;
	margin: -6px;
	cursor: ew-resize;
	z-index: 2;
}

.color1 {
	color: #140f0b;
}

.color2 {
	color: #1f2747;
}

.color3 {
	color: #4e4f8e;
}

.color4 {
	color: #8a7ddf;
}

.color5 {
	color: #e0b4f9;
}

.expl-btn {
	display: inline-block;
	position: relative;
	z-index: 1;
	width: 120px;
	padding: 10px 15px;
	font-size: 15px;
	text-align: center;
	text-decoration: none;
	text-transform: none;
	vertical-align: top;
	border-radius: 3px;
	line-height: 1.2;
	border: 2px solid #4e4f8e;
	background: #8a7ddf;
}

.expl-btn-main {
	cursor: pointer;
}

.expl-btn-main:hover {
	color: #e0b4f9;
	transition: all 0.1s ease-in-out;
}

.no-select {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.moment-text {
	color: whitesmoke;
	width: 100%;
	margin: 20px 4px;
	resize: none;
	padding: 10px;
	border-radius: 3px;
	line-height: 1.2;
	border: 2px solid #4e4f8e;
	background: #8a7ddf;
	font-size: 14px;
}

.moment-text::placeholder {
	color: whitesmoke;
}
</style>
